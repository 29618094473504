import React from "react";
import Logo from "../Header/img/logo.png";
import IMG1 from "../Header/img/img-01.png";
import IMG2 from "../Header/img/img-02.jpg";
import IMG3 from "../Header/img/img-03.png";

export default function Header() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <img src={Logo}/>
            <h1 className="text-uppercase">Sobre o projeto</h1>
            <p>
              As estimativas iniciais indicam que, aproximadamente, 450 milhões
              de pessoas no mundo sofrem de transtornos mentais , e de problemas
              psicossociais, como os relacionados com o uso abusivo do álcool e
              das drogas. O Brasil é o País com o maior número de ansiosos
              crônicos (9,3% da população de acordo com a OMS), e
              aproximadamente 30 milhões de brasileiros conhecem um familiar
              dependente químico. No Projeto Ondas a saúde mental depende da
              qualidade de vida, resgate das habilidades sociais, respeito
              próprio, relações interpessoais e do trabalho que formam a base
              estrutural para a construção de uma vida melhor. Dentro do
              processo geral de recuperação e tratamento de sintomas. A evolução
              na prática de atividade física e participação em um programa regular de
              exercícios, validam a importância dessa modalidade de intervenção
              efetiva no tratamento de declínios funcionais associados à doença
              mental. Quanto aos fatores psicológicos, os maiores benefícios
              incluem aumento da auto estima, melhora geral do humor, da
              percepção e da consciência corporal. Promove um alivio
              significativo nos processos de recuperação do uso de drogas e na
              redução de estados de depressão e ansiedade associadas a atividade
              física, as práticas ao ar livre, potencializam positivamente na
              busca da saúde mental. A terapia do surf, que combina os elementos
              terapêuticos do oceano com aventura do esporte, é uma nova opção
              reconhecida em todo o mundo por seus benefícios benefícios
              emocionais, fisicos e psicologicos.
            </p>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 img-center">
            <img className="img-thumbnail" src={IMG1}/>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <img className="img-thumbnail" src={IMG2}/>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 img-center">
            <br />
            <h3 className="text-uppercase">A praia e a terapia do surf</h3>

            <p>
              A “terapia do surfe”, vem se expandindo pelo mundo , com adeptos
              em países como Estados Unidos, Inglaterra, Austrália, Portugal,
              Peru, Espanha entre outros, e tem se mostrado especialmente útil
              na cura de doenças psicológicas. A International Surf Therapy
              Organization (ISTO)é um coletivo dos principais profissionais,
              clínicos, pesquisadores e influenciadores de surfterapia do mundo
              , que trabalham juntos para avançar na compreensão no setor, por
              meio de pesquisas e apoio a mais de 50 projetos. A praia possui
              benefícios tanto naturais, devido ao sol e ao mar, como sociais.
              Esse universo de diversidade coletiva, que preza pelo respeito
              mútuo e harmonia entre os frequentadores, proporciona um ambiente
              seguro para recuperação de portadores de transtornos mentais e
              dependentes químicos. O sol traz benefícios para depressão e
              melhora na qualidade do sono. O surf é um esporte que precisa de
              disciplina, equilíbrio , concentração, ajuste entre o pensamento e
              o movimento, obrigando o individuo a superar seus próprios
              limites. Produz adrenalina, serotonina e endorfina. Ajuda a
              aumentar a auto estima, a organização de hábitos e noção de
              limites. A confiança é a chave para terapias alternativas como o
              surf. O mar não olha para você, não julga, não tem opiniões. O
              surfe é uma prática poderosa de atenção plena. Para surfar o
              desenvolvimento do equilíbrio é fundamental , e os praticantes
              precisam ter consciência dos seus corpos, suas pranchas e sua
              localização. Isto ajuda a promover a autoconsciência, uma
              ferramenta poderosa para ter uma vida saudável. No Rio de Janeiro
              a praia do Arpoador , devido as suas características naturais ,
              sua história como um local iluminado para surf , sua diversidade
              de frequentadores , unindo todas as tribos humanas é o lugar ideal
              para essas atividades, promovendo com sucesso uma prática de saude
              mental positiva.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <h3 className="text-uppercase">NOSSO MÉTODO</h3>
            <p>
              Realizar atividades terapêuticas ao ar livre compostas de duas
              etapas por sessão : AULA DE SURF associadas a RODA DE CONVERSA .
              Serão oferecidas aulas, acompanhadas por professores capacitados e
              terapeutas para os pacientes, existindo a opção de exercício
              fisico e adaptação ao ambiente através da prática da caminhada na
              areia e na interação com o mar.
            </p>
            <h4>ETAPA 1 ATIVIDADE FISICA - 60 MINUTOS</h4>
            <p>AULA DE SURF INTERAÇÃO MAR CAMINHADA</p>
            <h4>ETAPA 2 RODA DE CONVERSA -30 MINUTOS</h4>
            <p>
              Roda de conversa , orientada pelo terapeuta, com participação dos
              pacientes após a atividade física, relatando sentimentos,
              dificuldades e sensações experimentadas com a prática. Integração
              com o grupo ao ar livre e acompanhamento do processo de evolução
              terapêutico, diretamente ligado a essas práticas e seus
              benefícios.
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <img className="img-thumbnail" src={IMG3}/>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <br />
            <h3 className="text-uppercase">PERIODICIDADE</h3>
            <p>DUAS VEZES POR SEMANA , NUM TOTAL DE TRÊS HORAS SEMANAIS</p>
            <h4>PERÍODO DO TRATAMENTO 3 MESES</h4>

            <h5 className="text-uppercase">Fase 1</h5>
            <p>
              Aumento do interesse social, melhoria nas condições físicas,
              elevação de bem estar e maior adaptabilidade para enfrentar
              obstáculos. Primeiros passos com a prancha e as ondas do mar,
              inicio do aprendizado . <strong>Realização da avaliação 1</strong>
              .
            </p>
            <h5 className="text-uppercase">Fase 2</h5>
            <p>
              Melhora significativa do humor e condições físicas, aumento do
              interesse social através da inclusão na prática, elevação do bem
              estar e maior adaptabilidade para enfrentar obstáculos. Dependendo
              da evolução o indivíduo pode começar a utilizar um prancha menor,
              ganhando mais autonomia.
              <strong>Realização da avaliação 2.</strong>
            </p>
            <h5 className="text-uppercase">Fase 3</h5>
            <p>
              Interação social bastante positiva, aumento da alegria e bem
              estar, auto confiança elevada e fortalecimento da auto estima. O
              indivíduo evolui em sua autonomia na prática do Surfe.
              <strong>Realização da avaliação final.</strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
